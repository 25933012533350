import {
  Button,
  // Dialog,
  // DialogBody,
  // DialogFooter,
  // DialogHeader,
  Input,
  Option,
  Select,
  Switch,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { GET_MACHINE_URL, SET_MAPPING_URL } from "../Constants";
import axios from "../api/axios";
import { useAuth } from "../store/auth";
import { useStore } from "../store/Zustand";
import MappingData from "./MappingData";
import Notification from "./Notification";

export default function Mapping() {
  const token = useAuth((state) => state.token);
  // drop-down machine value
  const [machine_list, set_machine_list] = useState([]);
  const [machine, setMachine] = useState("");

  // mapping variables
  const [mapping, setMapping] = useState({ operator_id: "", carefill_id: "" });
  const mapping_list = useStore((state) => state.mapping_list);
  const setMappingList = useStore((state) => state.setMappingList);

  const filter = useStore((state) => state.filter);
  const setFilter = useStore((state) => state.setFilter);
  const is_linked = useStore((state) => state.is_linked);

  // machine status message.
  const [machine_status, set_machine_status] = useState({
    status: false,
    message: 1,
  });

  // backend response message
  const [notify, setNotify] = useState({ status: 0, message: "" });

  // collecting input fields data
  const set_mapping = (e) => {
    setMapping((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Get machine list
  const get_machine_list = async () => {
    // console.log(token)
    await axios
      .get(GET_MACHINE_URL, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      },)
      .then((response) => {
        set_machine_list(response.data.data);
      })
      .catch((error) => {
        set_machine_list(error.response.data.data);
      });
  };

  // post request to create mapping
  const create_mapping = async () => {
    let is_machine = false;

    for (const iterator of machine_list) {
      if (iterator.machine_number === parseInt(machine)) {
        if (iterator.machine_status === 2) {
          // IN-USE
          is_machine = true;
          alert("Machine is in use")
          set_machine_status((prev) => ({ ...prev, message: 1 }));
        } else if (iterator.machine_status === 4) {
          // NEED - MAINTENANCE
          is_machine = true;
          set_machine_status((prev) => ({ ...prev, message: 2 }));
        } else if (
          iterator.machine_status === 0 ||
          iterator.machine_status === 3 ||
          iterator.machine_status === 5
        ) {
          is_machine = true;
          set_machine_status((prev) => ({ ...prev, message: 3 }));
        }
      }
    }

    const form = {
      operator_id: mapping.operator_id,
      carefill_id: mapping.carefill_id,
      machine_number: machine,
    };
    // console.log(form)

    if (!is_machine) {
      await axios
        .post(SET_MAPPING_URL, form, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        })
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            setMappingList(response.data.data);
            setNotify({ status: 1, message: response.data.status_message });
          } else {
            setNotify({ status: 4, message: "Unknown Error" });
          }
        })
        .catch((error) => {
          if (error.response.status === 409){
            alert(error?.response?.data?.status_message)
          } else{
          setNotify({ status: 3, message: "Response not found" });
          // alert("Server is Down " + error);
          }
        });
      setTimeout(() => {
        setNotify({ status: 0, message: "" });
      }, 1200);
      setMapping({ operator_id: "", carefill_id: "" }); // Reset form state
    } else {
      set_machine_status((prev) => ({ ...prev, status: true }));
    }
  };

  // if machine is in use post request to create mapping
  // const handle_create_mapping = async () => {
  //   set_machine_status((prev) => ({ ...prev, status: !prev.status }));
  //   if (machine_status.status) {
  //     const form = {
  //       operator_id: mapping.operator_id,
  //       carefill_id: mapping.carefill_id,
  //       machine_number: machine,
  //     };
  //     await axios
  //       .post(SET_MAPPING_URL, form, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": token,
  //         },
  //       })
  //       .then((response) => {
  //         if (response.status === 201) {
  //           setMappingList(response.data.data);
  //           setNotify({ status: 1, message: response.data.status_message });
  //         } else {
  //           setNotify({ status: 4, message: "Unknown Error" });
  //         }
  //       })
  //       .catch((error) => {
  //         setNotify({ status: 3, message: "Response not found" });
  //         // alert("Server is Down " + error);
  //       });
  //       setTimeout(() => {
  //         setNotify({ status: 0, message: "" });
  //       }, 1200);
  //   }
  // };

  useEffect(() => {
    get_machine_list();
  }, [mapping_list, filter, machine_status, is_linked, token]);


  return (
    <>
      <div className="grid min-h-screen min-w-full justify-center content-start border-solid mt-5 overflow-hidden">
        <h1 className="text-center text-3xl font-bold">
          Order Machine Mapping
        </h1>
        <div className="flex justify-end duration-1000">
          {notify && <Notification message={notify} />}
        </div>
        <div className="flex flex-row w-screen px-14 justify-evenly content-center mt-5">
          {/* <div className="w-64">
            <Input
              label="Operator Id"
              type="number"
              name="operator_id"
              value={mapping.operator_id}
              onChange={set_mapping}
            ></Input>
          </div> */}
          <div className="w-64">
            <Input
              label="Carefill Id"
              type="number"
              name="carefill_id"
              value={mapping.carefill_id}
              onChange={set_mapping}
            ></Input>
          </div>
          <div className="w-[150px]">
            <Select
              className="w-[200px]"
              label="Machine Name"
              onChange={(e) => {
                setMachine(e);
              }}
              placeholder="Select a Machine Name"
            >
              {machine_list.map((machine, index) => 
                  machine.machine_status === 1 ? (
                    <Option key={index} value={`${machine.machine_number}`}>
                      {`${index + 1} - ${machine.machine_name}`}
                    </Option>
                  ) : machine.machine_status === 2 ? (
                    <Option key={index}>Machine is mapped</Option>
                  ) : (
                    <Option key={index}>Machine not available</Option>
                  )
                )}
            </Select>
          </div>
          <div className="flex content-center w-max ml-4">
            <Button
              variant="gradient"
              size="md"
              color="blue"
              className="hidden lg:inline-block md:inline-block sm:inline-block xs:inline-block"
              onClick={create_mapping}
            >
              Go
            </Button>
          </div>
          <div className="flex content-center w-max ml-4">
            <Switch
              color="blue"
              onChange={() => {
                filter === true ? setFilter(false) : setFilter(true);
              }}
              checked={filter}
            />
          </div>
        </div>
        <div className="w-full max-h-min">
          <MappingData />
        </div>
      </div>

      {/* <Dialog
        open={machine_status.status}
        handler={() => {
          set_machine_status((prev) => ({ ...prev, status: !prev.status }));
        }}
        className="border p-6"
        style={{ minWidth: "33%", maxWidth: "33%" }}
      >
        <DialogHeader className="p-0 mb-4">
          {machine_status.message === 3 ? "ERROR" : "WARNING"}
        </DialogHeader>
        <DialogBody className="p-0 text-red-500 font-semibold">
          {machine_status.message === 1 &&
            "The machine is currently being used. Are you sure you want to proceed?"}
          {machine_status.message === 2 &&
            "The machine requires maintenance. Are you sure you want to proceed?"}
          {machine_status.message === 3 &&
            "This machine is not currently functional. It is not available for use at this time."}
        </DialogBody>
        {machine_status.message !== 3 && (
          <DialogFooter className="p-0">
            <Button
              variant="text"
              color="red"
              onClick={() => {
                set_machine_status((prev) => ({
                  ...prev,
                  status: !prev.status,
                }));
              }}
              className="mr-2"
            >
              <span>Cancel</span>
            </Button>
            <Button
              variant="gradient"
              color="green"
              onClick={handle_create_mapping}
            >
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        )}
      </Dialog> */}
    </>
  );
}
