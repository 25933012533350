import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Input,
  Option,
  Select,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { CREATE_MACHINE_URL, UPDATE_MACHINE_URL } from "../Constants";
import axios from "../api/axios";
import { useStore } from "../store/Zustand";
import { useAuth } from "../store/auth";

function MachineForm() {
  // to close machine create form
  const open = useStore((state) => state.open);
  const setOpen = useStore((state) => state.setOpen);
  const setNotify = useStore((state) => state.setNotify);
  const token = useAuth((state) => state.token);

  // edit machine
  const set_result = useStore((state) => state.set_result);
  const is_edit = useStore((state) => state.is_edit);
  const set_edit = useStore((state) => state.set_edit);
  const is_disabled = useStore((state) => state.is_disabled);
  const set_disabled = useStore((state) => state.set_disabled);
  const edit_data = useStore((state) => state.edit_data);
  const edit_machine_data = useStore((state) => state.edit_machine_data);

  // new machine values
  const [new_machine_status, set_new_Machine_Status] = useState("1");
  const [new_machine, set_new_Machine] = useState({
    machine_code: "",
    machine_name: "",
    machine_number: "",
    machine_version: "",
    software_version: "",
    last_service: "",
  });

  const create_machine = async () => {
    const form = {
      unit_id: 1,
      machine_code: new_machine.machine_code,
      machine_name: new_machine.machine_name,
      machine_number: new_machine.machine_number,
      machine_status: new_machine_status,
      machine_version: new_machine.machine_version,
      software_version: new_machine.software_version,
      // last_service: new_machine.last_service,
    };
    await axios
      .post(CREATE_MACHINE_URL, form, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
          setNotify({ status: 1, message: response.data.status_message });
          set_result(response.data.data);
      })
      .catch((error) => {
        setNotify({ status: 2, message: error.response.data.status_message });
        set_result(error.response.data);
      });
    setOpen(!open);
    setTimeout(() => {
      setNotify({ status: 0, message: "" });
    }, 2000);
    set_new_Machine({
      machine_code: "",
      machine_name: "",
      machine_number: "",
      machine_version: "",
      software_version: "",
      last_service: "",
    });
  };

  const update_machine = async () => {
    const form = {
      unit_id: 1,
      machine_id: edit_data.id,
      machine_code: edit_data.machine_code,
      machine_name: edit_data.machine_name,
      machine_number: edit_data.machine_number,
      machine_status: new_machine_status,
      machine_version: edit_data.machine_version,
      software_version: edit_data.software_version,
      // last_service: edit_data.last_service,
    };
    await axios
      .put(UPDATE_MACHINE_URL, form, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
        set_result(response.data.data);
      })
      .catch((error) => {
        if (error.status === 304) {
          console.log(error)
          alert("unable to update the status");
        } else {
          alert("Server is Down");
        }
      });
    setOpen(!open);
    set_edit(false);
  };

  const handleChange = (e) => {
    if (is_edit) {
      edit_machine_data({
        ...edit_data,
        [e.target.name]: e.target.value,
      });
    } else {
      set_new_Machine({
        ...new_machine,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    // console.log(new_machine);
    // console.log(edit_data);
    // console.log(is_edit);
    // console.log(new_machine_status);
    // console.log(is_disabled);
  }, [new_machine, new_machine_status, is_edit, edit_data, is_disabled]);

  return (
    <>
      <Dialog
        open={open}
        handler={() => {
          set_edit(false);
          setOpen(!open);
          set_new_Machine({
            machine_code: "",
            machine_name: "",
            machine_number: "",
            machine_version: "",
            software_version: "",
            // last_service: "",
          });
        }}
        className="bg-transparent shadow-none m-4 rounded-lg bg-white"
        style={{ maxWidth: "31%", minWidth: "31%" }}
      >
        <DialogHeader>
          <span className="w-full text-center"> Machine Form </span>
        </DialogHeader>
        <div className="w-full">
          <form className="p-6 bg-white rounded shadow-md py-8">
            <div>
              <div className="mb-4">
                <Input
                  size="lg"
                  name="machine_code"
                  label="Machine Code"
                  required
                  value={
                    is_edit ? edit_data.machine_code : new_machine.machine_code
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div>

              <div className="mb-4">
                <Input
                  size="lg"
                  name="machine_name"
                  label="Machine Name"
                  required
                  value={
                    is_edit ? edit_data.machine_name : new_machine.machine_name
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div>
              <div className="mb-4">
                <Input
                  size="lg"
                  name="machine_number"
                  label="Machine Number"
                  required
                  value={
                    is_edit
                      ? edit_data.machine_number
                      : new_machine.machine_number
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div>

              <div className="mb-4">
                <Select
                  label="Machine Status"
                  onChange={(e) => {
                    set_new_Machine_Status(e);
                  }}
                  value={is_edit ? String(edit_data.machine_status) : "1"}
                  placeholder="Select Machine Status"
                  disabled={is_disabled}
                  required
                >
                  <Option value="0">OFF</Option>
                  <Option value="1">IDLE</Option>
                  <Option value="2">IN USE</Option>
                  <Option value="3">OUT OF ORDER</Option>
                  <Option value="4">NEED MAINTENANCE</Option>
                  <Option value="5">IN MAINTENANCE</Option>
                </Select>
              </div>
              <div className="mb-4">
                <Input
                  size="lg"
                  name="machine_version"
                  label="Machine Version"
                  required
                  value={
                    is_edit
                      ? edit_data.machine_version
                      : new_machine.machine_version
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div>

              <div className="mb-4">
                <Input
                  size="lg"
                  name="software_version"
                  label="Software Version"
                  required
                  value={
                    is_edit
                      ? edit_data.software_version
                      : new_machine.software_version
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div>

              {/* <div className="mb-4">
                <Input
                  size="lg"
                  type="date"
                  name="last_service"
                  label="Last Service"
                  value={
                    is_edit ? edit_data.last_service : new_machine.last_service
                  }
                  onChange={handleChange}
                  disabled={is_disabled}
                />
              </div> */}
            </div>
          </form>
          <DialogFooter>
            <div className="flex flex-row content-end">
              <Button
                className="mr-1 bg-green-600 text-white hover:bg-gray-600"
                onClick={
                  is_edit
                    ? is_disabled
                      ? () => {
                          set_disabled(false);
                        }
                      : update_machine
                    : create_machine
                }
              >
                {is_edit ? (is_disabled ? `Edit` : `Save`) : `Submit`}
              </Button>
              <Button
                className="mr-1 bg-red-600 text-white hover:bg-gray-600"
                onClick={() => {
                  set_edit(false);
                  setOpen(!open);
                  set_new_Machine({
                    machine_code: "",
                    machine_name: "",
                    machine_number: "",
                    machine_version: "",
                    software_version: "",
                    // last_service: "",
                  });
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
}

export default MachineForm;
