import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useAuth } from "../store/auth";
import {
  DELETE_MACHINE_URL,
  GET_MACHINE_URL,
  UPDATE_MACHINE_STATUS_URL,
} from "../Constants";
import axios from "../api/axios";
import { useStore } from "../store/Zustand";
import MachineForm from "./MachineForm";
import Notification from "./Notification";

export default function MachineInfo() {
  const [machine_list, set_machine_list] = useState([]);
  const resetAuth = useAuth((state) => state.resetAuth);
  const token = useAuth((state) => state.token);
  const setOTP = useAuth((state) => state.setOTP);

  const [deleted, setDeleted] = useState(false);
  const result = useStore((state) => state.result);
  const set_result = useStore((state) => state.set_result);

  const notify = useStore((state) => state.notify);

  // using to display create machine form
  const open = useStore((state) => state.open);
  const setOpen = useStore((state) => state.setOpen);

  // edit machine
  const is_edit = useStore((state) => state.is_edit);
  const edit_data = useStore((state) => state.edit_data);
  const set_edit = useStore((state) => state.set_edit);
  const is_disabled = useStore((state) => state.is_disabled);
  const set_disabled = useStore((state) => state.set_disabled);
  const edit_machine_data = useStore((state) => state.edit_machine_data);

  const get_machine_list = async () => {
    await axios
      .get(GET_MACHINE_URL, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
        set_machine_list(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
        if(error.response.status === 403){
          resetAuth()
        // }else{
        //   alert("user not logged in")      
        }
        // alert("Server is Down " + error);
 
      });
  };

  const handle_edit = (data) => {
    set_edit(true);
    set_disabled(true);
    edit_machine_data(data);
    setOpen(!open);
  };

  const handle_delete = async (id) => {
    await axios
      .delete(`${DELETE_MACHINE_URL}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
          setDeleted(true);
          // alert("Are you sure want to delete")
      })
      .catch((error) => {
        if(error.status === 304){
          alert("Please unlink it before deleting")
        }else if(error.status === 404){
          alert("Machine not found")
        }
        else{
          alert("Server is Down " + error);
        }
      });
  };

  const handle_machine_status = async (e, data) => {
    const form = {
      machine_id: data.id,
      machine_status: e.target.value,
    };
    await axios
      .put(UPDATE_MACHINE_STATUS_URL, form, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
          set_result(response.data.data);
      })
      .catch((error) => {
        if(error.status === 304){
          alert("Please unlink it before updating status")
        }else if (error.status === 400){
          alert("Cannot update to In Use ")
        }else{
        set_result(error.response.data);
        alert(error.response)
        }
      });
  };

  useEffect(() => {
    get_machine_list();
    setDeleted(false);
    setOTP("");
  }, [result, edit_data, is_edit, open, deleted, is_disabled]);

  return (
    <>
      <h1 className="text-center text-2xl font-bold pt-5">
        <span>Machine Information</span>
        <span className="flex justify-end duration-1000">
          {notify && <Notification message={notify} />}
        </span>
      </h1>
      <div className="flex flex-row w-screen px-14 justify-end my-2">
        <Button
          variant="gradient"
          size="md"
          color="blue"
          className="hidden lg:inline-block md:inline-block sm:inline-block xs:inline-block"
          onClick={() => {
            setOpen(!open);
            set_edit(false);
            set_disabled(false);
          }}
        >
          Create Machine
        </Button>
      </div>
      <div className="w-full px-10 py-2 overscroll-y-auto">
        <Card className="h-full w-full">
          <table className="w-full min-w-max table-auto text-center">
            <thead>
              <tr className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Sr. no.
                </th>
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Machine Status
                </th>
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Machine Code
                </th>
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Machine Version
                </th>
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Machine Name
                </th>
                {/* <th className="text-center p-4 font-normal leading-none opacity-70">
                  Last Service
                </th> */}
                <th className="text-center p-4 font-normal leading-none opacity-70">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>

              {machine_list.length === 0 && 
                (
                  <tr>
                    <td className="p-2">Data Not Found</td>
                  </tr>
                )
              }

              {machine_list.length > 0 && (
                machine_list.map((data, index) => {
                  return (
                    <tr
                      className={
                        data.machine_status === 1
                          ? // ? "bg-[#22fb2269] border border-gray-400"
                            " bg-green-200 border border-gray-400"
                          : data.machine_status === 2 ||
                            data.machine_status === 4
                          ? // ? "bg-[#f9a71072] border border-gray-400"
                            " bg-orange-200 border border-gray-400"
                          : " bg-red-200 border border-gray-400"
                      }
                      key={index}
                    >
                      <td className="text-center p-5">{index + 1}</td>
                      <td className="text-center p-5">
                        {data.machine_status === 0 && "OFF"}
                        {data.machine_status === 1 && "IDLE"}
                        {data.machine_status === 2 && "IN USE"}
                        {data.machine_status === 3 && "OUT OF ORDER"}
                        {data.machine_status === 4 && "NEED MAINTENANCE"}
                        {data.machine_status === 5 && "IN MAINTENANCE"}
                      </td>
                      <td className="text-center p-5">{data.machine_code}</td>
                      <td className="text-center p-5">
                        {data.machine_version}
                      </td>
                      <td className="text-center p-5">{data.machine_name}</td>
                      {/* <td className="text-center p-5">{data.last_service}</td> */}
                      <td className="text-center p-2 cursor-pointer flex items-center justify-evenly">
                        <Button
                          className="bg-white p-3 border border-gray-400 ml-1"
                          onClick={() => {
                            handle_edit(data);
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-orange-500 h-4 w-4"
                            icon={faEdit}
                          />
                        </Button>
                        <Button
                          className="bg-white p-3 border border-gray-400 ml-1"
                          onClick={() => {
                            handle_delete(data.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-orange-500 h-4 w-4"
                            icon={faTrashCan}
                          />
                        </Button>
                        <select
                          className="peer w-[145px] h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200"
                          value={data.machine_status}
                          onChange={(e) => {
                            handle_machine_status(e, data);
                          }}
                        >
                          <option value="0">OFF</option>
                          <option value="1">IDLE</option>
                          <option value="2">IN USE</option>
                          <option value="3">OUT OF ORDER</option>
                          <option value="4">NEED MAINTENANCE</option>
                          <option value="5">IN MAINTENANCE</option>
                        </select>
                      </td>
                    </tr>
                  );
                })
              )}


            </tbody>
          </table>
        </Card>
      </div>

      {/* ----------------------------------------------------------- */}

      <MachineForm />
    </>
  );
}
