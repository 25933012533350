import React from "react";

function Notification(props) {
  return (
    <>
      {props.message.status === 1 && (
        <label className="w-2/5 px-4 rounded-md border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-lg text-[#2ec946] py-1 my-1">
          <p>{props.message.message}</p>
        </label>
      )}
      {props.message.status === 2 && (
        <label className="w-2/5 px-4 rounded-md border-l-4 border-orange-300 bg-[#2ec946]/10 font-medium text-lg text-orange-300 py-1 my-1">
          <p>{props.message.message}</p>
        </label>
      )}
      {props.message.status === 3 && (
        <label className="w-2/5 px-4 rounded-md border-l-4 border-red-500 bg-[#2ec946]/10 font-medium text-lg text-red-500 py-1 my-1">
          <p>{props.message.message}</p>
        </label>
      )}
      {props.message.status === 4 && (
        <label className="w-2/5 px-4 rounded-md border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-lg text-[#2ec946] py-1 my-1">
          <p>{props.message.message}</p>
        </label>
      )}
    </>
  );
}

export default Notification;
